import validate from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import clear_45errors_45global from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/middleware/clearErrors.global.ts";
export const globalMiddleware = [
  validate,
  clear_45errors_45global
]
export const namedMiddleware = {
  auth: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/middleware/auth.ts"),
  "disable-on-guest-app-mode": () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/middleware/disableOnGuestAppMode.ts"),
  guest: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/middleware/guest.ts")
}