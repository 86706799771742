import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { Offline as OfflineIntegration } from "@sentry/integrations";

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;

  // Sentry is only initialized on the client side and not during development (unless specified).
  if (
    process.client &&
    (!process.dev || useRuntimeConfig().public.sentryEnabled)
  ) {
    Sentry.init({
      app: [vueApp],
      dsn: "https://cc7c9b0e3ac8444fb80a6b182e00709c@o4504565749645312.ingest.sentry.io/4505050470285312",
      environment: process.dev ? "development" : "production",
      trackComponents: true,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(
            nuxtApp.$router
          ),
        }),
        new OfflineIntegration({
          maxStoredEvents: 50,
        }),
      ],
      tracesSampleRate: process.dev ? 0.8 : 0.4,
      normalizeDepth: 5,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line no-console
          console.error(
            `[Exeption handled by Sentry]: (${hint.originalException})`,
            { event, hint }
          );
        }
        // Continue sending to Sentry
        return event;
      },
    });
  }

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "mount", "update"],
    })
  );

  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };

  // Usage globally:
  // const { $sentryCaptureException } = useNuxtApp();
  // $sentryCaptureException(new Error("test"));
});
