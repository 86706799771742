// vue-toaster custom composable as it only has this.$toast available (no setup usage)
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({
  max: 5,
  position: "top-right",
  duration: 5000,
});

interface ToastOptions {
  message?: string;
  type?: "success" | "info" | "warning" | "error" | "default";
  position?:
    | "top"
    | "bottom"
    | "top-right"
    | "bottom-right"
    | "top-left"
    | "bottom-left";
  duration?: number;
  dismissible?: boolean;
  onClick?: Function;
  onClose?: Function;
  queue?: boolean;
  maxToasts?: number;
  pauseOnHover?: boolean;
  useDefaultCss?: boolean;
}

/**
 * Create Toast Notification
 *
 */
export const useToast = (): {
  show(message: string, options?: ToastOptions): Function;
  success(message: string, options?: ToastOptions): Function;
  error(message: string, options?: ToastOptions): Function;
  warning(message: string, options?: ToastOptions): Function;
  info(message: string, options?: ToastOptions): Function;
  clear(): Function;
} => toaster;
