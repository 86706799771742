export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"src":"/js/jquery.js"},{"src":"/js/popper.js"},{"src":"/js/bootstrap.js"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"