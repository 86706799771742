<template>
  <div>
    <NuxtLayout>

      <NuxtPage />

      <!-- WORKING OFFLINE INDICATOR -->
      <UseOnline v-slot="{ isOnline }">
        <div
          class="fixed z-50 flex items-center justify-center gap-3 p-2 text-white transition-all transform -translate-x-1/2 bg-red-400 border-r-4 border-orange-800 rounded-lg select-none top-20 left-1/2 offline-bg-to-none"
          role="alert"
          v-if="!isOnline"
        >
          <NoInternet class="bg-red-400 rounded-full w-7 h-7 offline-enlarge-icon" />
          <p class="overflow-hidden font-bold tracking-wide text-md offline-w-to-zero">OFFLINE</p>
        </div>
      </UseOnline>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { UseOnline } from '@vueuse/components';
import Cookies from "js-cookie";
import { AUTH_USER_STORE_NAME } from "~~/composables/states";
import { attemptToPersistStorage } from './helpers/storageManager';
import NoInternet from './components/icons/NoInternet.vue';

const authUser = useAuthUser();
const route = useRoute();
const title = useRuntimeConfig().public.title;

useHead({
  titleTemplate: (titleChunk) => {
    const suffix = title + " - Data Collection";

    return titleChunk ? `${titleChunk} - ${suffix}` : suffix;
  },
  meta: [
    {
      name: "description",
      content:
        `Offline form record collection application for PHL MFI, made by Build Change. Deployed for ${title}.`,
    },
  ],
});

// Cookie Setter for Server-Render when User locally changes
watch(
  authUser,
  async (newAuthUser) => {
    if (newAuthUser) {
      Cookies.set(AUTH_USER_STORE_NAME, JSON.stringify(newAuthUser));

      await nextTick();
      // If is in login page redirect to forms
      if (route.name === "index") {
        navigateTo("/forms", {
          replace: true,
        })
      }

      // Let's Attempt to Persist the Storage (when logged in)
      // (a bit longer so that browser does not randomly release it after some time.)
      await attemptToPersistStorage();
    } else {
      Cookies.remove(AUTH_USER_STORE_NAME);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
