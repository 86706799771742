import {
  createGlobalState,
  CreateGlobalStateReturn,
  RemovableRef,
  StorageSerializers,
  useStorage,
} from "@vueuse/core";
import { FormsTypes } from "~~/types/forms";

export interface ValidationErrorResponse {
  message?: string;
  errors?: object;
}

import { LoginResponse } from "./useLogin";

export const AUTH_USER_STORE_NAME = "auth-user";

// Global Auth User Data (stored/fetched to/from local storage)
export const useAuthUser: CreateGlobalStateReturn<
  RemovableRef<LoginResponse | null>
> = createGlobalState(() =>
  useStorage(AUTH_USER_STORE_NAME, null, undefined, {
    serializer: StorageSerializers.object,
  })
);

// Store Specifically Validation Errors
export const useErrors = () =>
  useState<ValidationErrorResponse>("errors", () => ({}));

// Stores Global List of Forms
export const useForms = () => useState<FormsTypes[]>("forms", () => []);

export const usePersistedStoragePrompted = createGlobalState(() =>
  useStorage("persisted-storage-confirmation-prompted", false)
);
