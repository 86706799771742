/**
 * Triggers a browser storage persist action.
 */
export async function persist(): Promise<boolean> {
  if (navigator.storage && navigator.storage.persist) {
    return await navigator.storage.persist();
  }

  return false;
}

/**
 * Get the browser storage persistence status.
 */
export async function isStoragePersisted(): Promise<boolean> {
  if (navigator.storage && navigator.storage.persisted) {
    return await navigator.storage.persisted();
  }

  return false;
}

/**
 * Custom Estimated Quota Type Response
 */
export interface EstimatedQuotaType {
  usage: string;
  quota: string;
  remaining: string;
  percentageUsed: string;
  percentageUsedNumber: number;
}

/**
 * Function to Convert bytes to other higher formats.
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

/**
 * Gets the Estimated Quota of storage (or false)
 */
export async function getEstimatedQuota(): Promise<EstimatedQuotaType | false> {
  if (navigator.storage && navigator.storage.estimate) {
    const { quota, usage } = await navigator.storage.estimate();

    if (!quota || !usage) {
      return false;
    }

    const percentageUsed = (usage / quota) * 100;
    const remaining = quota - usage;

    return {
      usage: formatBytes(usage),
      quota: formatBytes(quota),
      remaining: formatBytes(remaining),
      percentageUsed: percentageUsed.toFixed(2) + "%",
      percentageUsedNumber: percentageUsed,
    };
  } else {
    console.error(
      "Storage Manager not found. Your device/browser cannot work in Offline Mode."
    );
    return false;
  }
}

/**
 * Used for attempting to persist the storage.
 */
export async function attemptToPersistStorage(): Promise<boolean> {
  // Check if storage is already persisted.
  const isAlreadyPersisted = await isStoragePersisted();

  if (isAlreadyPersisted) {
    console.info("Storage has been persisted.");
    return true;
  } else {
    const title = useRuntimeConfig().public.title;

    let persisted = false;
    // Try to persist immediately.
    persisted = await persist();

    // Check if we can query the allowed permissions and check the status. (if not already persisted)
    let permissionStatus = "granted";
    if (!persisted) {
      if (navigator.permissions && navigator.permissions.query) {
        const permission = await navigator.permissions.query({
          name: "persistent-storage",
        });
        permissionStatus = permission.state;
      }
    }

    // Try to persist.
    console.info("Trying to persist storage...");

    // If permission is already granted. call persist directly. (if not already persisted)
    if (permissionStatus === "granted" && !persisted) {
      persisted = await persist();
    }
    // Else try to prompt user to confirm and then request persist again. Hopefully works. (Remember: cannot be 100% sure that it persisted.)
    else if (permissionStatus === "prompt") {
      if (
        !usePersistedStoragePrompted().value &&
        confirm(
          `${title} -\nwants to make the storage persisted, for it to work better offline.\n\nDo you want to allow this application to persist data?`
        )
      ) {
        persisted = await persist();
      }

      usePersistedStoragePrompted().value = true;
    }

    // Return persisted status.
    if (persisted) {
      console.info(
        "Storage persisted successfully for now. At: " +
          new Date().toLocaleString()
      );
      return true;
    } else {
      console.error(
        "Failed to persist storage. Either you are low on storage or your browser/os does not allow it at the moment."
      );
      return false;
    }
  }
}
