export default defineNuxtPlugin(() => {
  useHead({
    meta: [{ name: "theme-color", content: "#92bc34" }],
    link: [
      { rel: "icon", href: "/img/icon.png" },
      { rel: "apple-touch-icon", href: "/img/icon.png" },
      { rel: "manifest", href: "/manifest.json" },
    ],
  });

  if (process.client && "serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js")
      .then(() =>
        console.log(
          "Service worker is registered! Install the app and use offline."
        )
      )
      .catch(() =>
        console.error(
          "Service worker registration failed. Try hard reloading the page."
        )
      );

    navigator.serviceWorker
      .getRegistration()
      .then((r) => {
        if (r && r.active && r.active.state == "activated") {
          r.update(); // Force Update
        }

        if (r) {
          r.addEventListener("updatefound", (evt) => {
            // If Force Update found some update
            const swInstalling = r.installing;

            if (swInstalling) {
              swInstalling.addEventListener("statechange", (evt) => {
                if (swInstalling.state === "installed") {
                  console.log("Service worker update was installed.");
                  // alert("New version of this web application is available.");

                  // Show toast with update button
                  useToast().show(
                    `<span class="mb-1 text-lg">New version of this web application is available.</span>
                    <b>Please reload to see new changes.</b>
                    <div>
                    <button onClick="location.reload()" class="inline-block px-4 py-1 mt-1 mb-1 text-sm bg-teal-500 rounded-md hover:bg-teal-600 w-fit">Update Now</button>
                    <button class="inline-block px-4 py-1 mt-2 mb-1 ml-1 text-sm bg-gray-500 rounded-md hover:bg-gray-600 w-fit">Close</button>
                    </div>`,
                    {
                      dismissible: true,
                      duration: false as any,
                      position: "bottom",
                      queue: false,
                    }
                  );
                }
              });
            }
          });

          // Check for sw update every 1 minute manually (without relying on browser's decision)
          setInterval(() => {
            r.update();
          }, 60 * 1000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    if (window) {
      console.warn("Service worker is not supported. You cannot use offline.");
    }
  }
});
