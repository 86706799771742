import { default as pages_47forms_47_91slug_93_46vueMeta } from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/forms/[slug].vue?macro=true";
import { default as pages_47forms_47index_46vueMeta } from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/forms/index.vue?macro=true";
import { default as pages_47index_46vueMeta } from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/index.vue?macro=true";
import { default as pages_47login_46vueMeta } from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/login.vue?macro=true";
import { default as pages_47magic_45link_45sent_46vueMeta } from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/magic-link-sent.vue?macro=true";
import { default as pages_47pending_46vueMeta } from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/pending.vue?macro=true";
export default [
  {
    name: pages_47forms_47_91slug_93_46vueMeta?.name ?? "forms-slug",
    path: pages_47forms_47_91slug_93_46vueMeta?.path ?? "/forms/:slug",
    children: [],
    meta: pages_47forms_47_91slug_93_46vueMeta,
    alias: pages_47forms_47_91slug_93_46vueMeta?.alias || [],
    redirect: pages_47forms_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/forms/[slug].vue").then(m => m.default || m)
  },
  {
    name: pages_47forms_47index_46vueMeta?.name ?? "forms",
    path: pages_47forms_47index_46vueMeta?.path ?? "/forms",
    children: [],
    meta: pages_47forms_47index_46vueMeta,
    alias: pages_47forms_47index_46vueMeta?.alias || [],
    redirect: pages_47forms_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/forms/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47index_46vueMeta?.name ?? "index",
    path: pages_47index_46vueMeta?.path ?? "/",
    children: [],
    meta: pages_47index_46vueMeta,
    alias: pages_47index_46vueMeta?.alias || [],
    redirect: pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47login_46vueMeta?.name ?? "login",
    path: pages_47login_46vueMeta?.path ?? "/login",
    children: [],
    meta: pages_47login_46vueMeta,
    alias: pages_47login_46vueMeta?.alias || [],
    redirect: pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/login.vue").then(m => m.default || m)
  },
  {
    name: pages_47magic_45link_45sent_46vueMeta?.name ?? "magic-link-sent",
    path: pages_47magic_45link_45sent_46vueMeta?.path ?? "/magic-link-sent",
    children: [],
    meta: pages_47magic_45link_45sent_46vueMeta,
    alias: pages_47magic_45link_45sent_46vueMeta?.alias || [],
    redirect: pages_47magic_45link_45sent_46vueMeta?.redirect || undefined,
    component: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/magic-link-sent.vue").then(m => m.default || m)
  },
  {
    name: pages_47pending_46vueMeta?.name ?? "pending",
    path: pages_47pending_46vueMeta?.path ?? "/pending",
    children: [],
    meta: pages_47pending_46vueMeta,
    alias: pages_47pending_46vueMeta?.alias || [],
    redirect: pages_47pending_46vueMeta?.redirect || undefined,
    component: () => import("E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/pages/pending.vue").then(m => m.default || m)
  }
]