import _nuxt_components_plugin_mjs_KR1HBZs4kY from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import modules_pwa_runtime_plugin_ts_bmfoBeQ3iQ from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/modules/pwa/runtime/plugin.ts";
import node_modules_nuxt_gtag_dist_runtime_plugin_client_mjs_LcKgStRyi6 from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_sentry_client_ts_shVUlIjFLk from "E:/New/aski-pwa-app-code/aski-pwa-app-code/aski-pwa-app-code/plugins/sentry.client.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm,
  node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX,
  modules_pwa_runtime_plugin_ts_bmfoBeQ3iQ,
  node_modules_nuxt_gtag_dist_runtime_plugin_client_mjs_LcKgStRyi6,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_sentry_client_ts_shVUlIjFLk
]